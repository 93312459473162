function trataTextoDataLayer(valor) {
  return valor
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[çÇ]/g, 'c') // Substitui ç e Ç por c
    .replace(/[^\w\s]/gi, '_')
    .replace(/\s+/g, '_') // Substitui espaços por hífen
    .replace(/-+/g, '_') // Evita que dois hífens fiquem seguidos
    .replace(/^-|-$/g, '') // Remove hífen no início ou no final da string
    .toLowerCase();
}

function trataEmpreendimentoIdDataLayer() {
  return $('#empreendimentoId').attr('title').toString().toLowerCase().replace(/\_/g, '-');
}

//Data Lake header
function dataLayerCliqueHeader(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':geral',
      eventAction: 'clique:header',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

$(document).ready(() => {
  $('#header--rebranding .search__trigger').attr('onclick', "dataLayerCliqueHeader('busca')");
  $('header .user-menu__trigger--login').attr('onclick', "dataLayerCliqueHeader('login')");
  $('header .schedule__trigger').attr('onclick', "dataLayerCliqueHeader('horario-funcionamento')");

  $('#consentimento-optin').change(function () {
    dataLayerCheckedOptIn($(this));
  });

  $('.commercial__info-block a').attr('onclick', 'dataLayerComercializacao(this)');
  $('.historia a').attr('onclick', 'dataLayerPremium(this)');
  $('.unsubscribe a').attr('onclick', 'dataLayerPoliticas(this)');
  //footer
  $('.footer__address .footer__link--outline').attr(
    'onclick',
    "dataLayerFooterItem('como-chegar')"
  );
  $('#sortCriteria').attr('onchange', 'dataLayerBusca(this)');
  dataLayerB2c();

  const url = window.location.href;
  if (url.includes('como-chegar')) {
    $('.accordion__label').attr('onclick', 'dataLayerComoChegar(this)');
  }
});

function dataLayerTermoBuscadoHeader(nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'search',
      search_term: trataTextoDataLayer(nomeItem),
    });
  }
}

//Data Lake Menu
function dataLayerMenuItem(nomeItem, secao, nomeEvento) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: nomeEvento,
      secao: trataTextoDataLayer(secao),
      nome_elemento: trataTextoDataLayer(nomeItem),
    });
  }
}

function dataLayerCliqueHorarioFuncionamentoHome() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':geral',
      eventAction: 'clique:menu',
      eventLabel: trataTextoDataLayer('horario-funcionamento:ver-outros-horarios'),
    });
  }
}

//Data Leke Menu Logado
function dataLayerMenuLogado(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada',
      eventAction: 'clique:menu',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLayerMenuSubItem(secao, nomeItem, nomeEvento) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: nomeEvento,
      secao: trataTextoDataLayer(secao),
      nome_elemento: trataTextoDataLayer(nomeItem),
    });
  }
}

function dataLakeRedesSociaisFooter(nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':geral',
      eventAction: 'clique:footer',
      eventLabel: trataTextoDataLayer(nomeItem),
    });
  }
}

//Data Lake Botão Flutuante Home
function dataLayerHomeBotaoFlutuante(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':home',
      eventAction: 'clique:botao-flutuante',
      eventLabel: trataTextoDataLayer($(element).find('span').text()),
    });
  }
}

function dataLayerHomeAgenda(elem) {
  if (window.dataLayer && window.dataLayer !== '') {
    const label = $(elem).prev().find('.calendar-list__title').text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':agenda-eventos',
      eventAction: 'clique:link',
      eventLabel: 'agenda:' + trataTextoDataLayer(label),
    });
  }
}
function dataLayerPublicidade() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':home',
      eventAction: 'clique:publicidade',
      eventLabel: 'publicidade',
    });
  }
}
function dataLayerCheckedOptIn(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    let acao = $(element).is(':checked');

    if (acao) {
      acao = 'marcou';
    } else {
      acao = 'desmarcou';
    }

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':home',
      eventAction: 'interacao:check',
      eventLabel: acao,
    });
  }
}
function dataLayerEnviaOptIn() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':home',
      eventAction: 'clique:botao',
      eventLabel: 'autorizacao-de-notificacao',
    });
  }
}
function dataLayerComercializacao(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const nomeItem = $(element).parent().prev().text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':comercializacao',
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer(nomeItem),
    });
  }
}
function dataLayerPremium(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const nomeItem = $(element).text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':parque-premium',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer(nomeItem),
    });
  }
}

//Data Lake Botão Flutuante Por Página
function dataLayerBotaoFlutuante(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    let nomePagina = '';

    if (typeof isHomeAliansce !== 'undefined') nomePagina = ':home';

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + nomePagina,
      eventAction: 'clique:botao-flutuante',
      eventLabel: trataTextoDataLayer($(element).find('span').text()),
    });
  }
}

//Data Lake Loja
function dataLayerLojaTermoBuscado(campo) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'interacao:campo:busca',
      eventLabel: trataTextoDataLayer(campo),
    });
  }
}

function dataLayerLojaTermoBuscadoHome(campo) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':home',
      eventAction: 'interacao:campo:lojas',
      eventLabel: 'busca:' + trataTextoDataLayer(campo),
    });
  }
}

function dataLayerLojaFiltroSegmentoHome(campo) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':home',
      eventAction: 'interacao:filtro:lojas',
      eventLabel: 'filtre-por-categoria:' + trataTextoDataLayer(campo),
    });
  }
}

function dataLayerSubSegmento(segmento, subSegmento) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'interacao:filtro',
      eventLabel: trataTextoDataLayer(segmento) + ':' + trataTextoDataLayer(subSegmento),
    });
  }
}

function dataLayerSegmentoMobile(segmento) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'interacao:filtro',
      eventLabel: trataTextoDataLayer(segmento),
    });
  }
}
function dataLayerSegmento(element) {
  let acaoBtn = '';
  const segmento = $(element).text();
  if ($(element).parent().next().css('display') == 'none') {
    acaoBtn = 'fechou';
  } else {
    acaoBtn = 'abriu';
  }
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'interacao:filtro',
      eventLabel: trataTextoDataLayer(segmento + ':' + acaoBtn),
    });
  }
}

function dataLayerLimpaFiltro() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer('limpar-filtro'),
    });
  }
}

function dataLayerCliqueLoja(nomeLoja) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'clique:loja',
      eventLabel: trataTextoDataLayer(nomeLoja),
      dimension5: trataTextoDataLayer(nomeLoja),
    });
  }
}

function dataLayerCliqueLanche(nomeLoja) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':pausa-para-lanche',
      eventAction: 'clique:loja',
      eventLabel: trataTextoDataLayer(nomeLoja),
      dimension5: trataTextoDataLayer(nomeLoja),
    });
  }
}

function dataLayerCliqueLancheVerTodas() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':pausa-para-lanche',
      eventAction: 'clique:link',
      eventLabel: 'ver-todas',
    });
  }
}

function dataLayerCliqueBtnLoja(nomeLoja, element) {
  let btnLoja = $(element).text().replace('.st0{fill:#7f7f7f}', '');

  btnLoja = btnLoja.replace('.st0{fill:#7F7F7F;}', '');
  btnLoja = btnLoja.trim();

  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'clique:botao:loja',
      eventLabel: trataTextoDataLayer(nomeLoja + ':' + btnLoja),
      dimension5: trataTextoDataLayer(nomeLoja),
    });
  }
}

function dataLayerClickOrdenacao(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'clique:botao:ordenacao',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLayerClickLetraFiltro(letra) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'clique:link:letra',
      eventLabel: trataTextoDataLayer(letra),
    });
  }
}

function dataLayerTipo() {
  const area = $('.store-detail').length > 0 ? 'detalhe-de-loja' : 'lista-de-lojas';

  return isAlimentacao == 'true' ? 'alimentacao' : 'lojas';
}

//Data Lake Fale Conosco
function dataLayerFaleConoscoEnviar() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fale-conosco',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer('enviar'),
    });
  }
}

function dataLayerFaleConoscoButton(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fale-conosco',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLayerFaleConoscoCallback(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fale-conosco',
      eventAction: 'callback:formulario',
      eventLabel: trataTextoDataLayer($('<textarea />').html(label).text()),
    });
  }
}

function trataMensagemErro(erros) {
  let mensagemErro = '';
  $(erros).each(index => {
    const nomeCampoErro = $(erros[index].element).attr('placeholder');
    const erroCampo = $(erros[index]).message;
    mensagemErro += nomeCampoErro + ':' + erroCampo;
  });
  return mensagemErro;
}

function dataLayerFaleConoscoCampoPreenchido(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const campo = $(element).attr('id');
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fale-conosco',
      eventAction: 'interacao:campo:formulario',
      eventLabel: trataTextoDataLayer('preencheu:' + campo),
    });
  }
}

//Data Lake Editar Perfil
function dataLayerPerfilMenuLateral(nomeMenuLateral) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'clique:menu',
      eventLabel: trataTextoDataLayer(nomeMenuLateral),
    });
  }
}

function dataLayerPerfilCamposFormulario(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const nomeCampo = $(element).attr('id');

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'interacao:formulario-cadastro:campo',
      eventLabel: trataTextoDataLayer('preencheu:' + nomeCampo),
    });
  }
}

function dataLayerPerfilFormularioCheckBox(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    let checkCampo = '';
    const isChecked = $(element).prop('checked');
    const nomeCheck = $(element).next().text();

    if (isChecked) {
      checkCampo = 'marcou';
    } else {
      checkCampo = 'desmarcou';
    }
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'interacao:checkbox:formulario-dados-de-cadastro',
      eventLabel: trataTextoDataLayer(checkCampo + ':' + nomeCheck),
    });
  }
}

function dataLayerPerfilBotaoEnviar(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'clique:botao:formulario-dados-de-cadastro',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLayerPerfilSucessoError(status) {
  if (window.dataLayer && window.dataLayer !== '') {
    const statusDecode = $('<textarea />').html(status).text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'callback:formulario-dados-de-cadastro',
      eventLabel: trataTextoDataLayer(statusDecode),
    });
  }
}

//Data Lake Perfil-meus cartoes
function dataLayerPerfilCartaoDeCreditoCampos(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const nomeCampo = $(element).attr('id');

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'interacao:formulario-meus-cartoes:campo',
      eventLabel: trataTextoDataLayer('preencheu:' + nomeCampo),
    });
  }
}

function dataLayerPerfilCartaoAdicionar(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const nomeCampo = $(element).attr('id');

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'clique:botao:meus-cartoes',
      eventLabel: 'adicionar-novo-cartao',
    });
  }
}

function dataLayerPerfilCartaoDeCreditoBotoes(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'clique:botao:meus-cartoes',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLayerPerfilCartaoDeCreditoSucessoError(status) {
  if (window.dataLayer && window.dataLayer !== '') {
    const statusDecode = $('<textarea />').html(status).text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:editar-perfil',
      eventAction: 'callback:formulario-meus-cartoes',
      eventLabel: trataTextoDataLayer(statusDecode),
    });
  }
}

//Data Lake Cinema
function dataLayerHomeCinemaCliqueFilme(nomeFilme) {
  if (window.dataLayer && window.dataLayer !== '') {
    let nomePagina = '';

    if (typeof isHomeAliansce !== 'undefined') nomePagina = ':home';

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + nomePagina,
      eventAction: 'clique:card',
      eventLabel: trataTextoDataLayer('cinema:' + nomeFilme),
    });
  }
}

function dataLayerCinemaComprarHome(tituloFilme) {
  if (window.dataLayer && window.dataLayer !== '') {
    let nomePagina = '';

    if (typeof isHomeAliansce !== 'undefined') nomePagina = ':home';

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + nomePagina,
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer('comprar:' + tituloFilme),
    });
  }
}

function dataLayerCinemaHomeTodosOsFilmes() {
  if (window.dataLayer && window.dataLayer !== '') {
    let nomePagina = '';

    if (typeof isHomeAliansce !== 'undefined') nomePagina = ':home';

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + nomePagina,
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer('cinema:todos-os-filmes'),
    });
  }
}
function dataLayerCinemaComprar(element, dataFilme, horaFilme) {
  if (window.dataLayer && window.dataLayer !== '') {
    let tituloFilme = $(element)
      .parent()
      .parent()
      .parent()
      .parent()
      .parent()
      .parent()
      .find('h3')
      .text();

    if (tituloFilme == '') {
      tituloFilme = $(element)
        .parent()
        .parent()
        .parent()
        .parent()
        .parent()
        .parent()
        .parent()
        .parent()
        .find('h3')
        .text();
    }

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':cinema',
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer('comprar:' + tituloFilme + ':' + dataFilme + ':' + horaFilme),
    });
  }
}

function dataLayerCinemaConfiraOsPrecos() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':cinema',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer('confira-os-precos-de-bilheteria'),
    });
  }
}

//Data Lake Mapa
function dataLayerFacilidadesVerMapa(label, item) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':facilidades',
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer(item) + ':' + trataTextoDataLayer(label),
    });
  }
}

function dataLayerFacilidadesAcao(elem) {
  if (window.dataLayer && window.dataLayer !== '') {
    let statusClique = '';
    const label = $(elem).parent().attr('data-name');

    if ($(elem).parent().hasClass('visible')) {
      statusClique = 'abriu';
    } else {
      statusClique = 'fechou';
    }

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':facilidades',
      eventAction: 'interacao:itens',
      eventLabel: trataTextoDataLayer(label + ':' + statusClique),
    });
  }
}

function dataLayerFacilidadesLinkInterno(elem) {
  if (window.dataLayer && window.dataLayer !== '') {
    const linkInterno = $(elem).text();
    const nomeFacilidade = $(elem).parents('.facilidade-item').attr('data-name');

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':facilidades',
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer(nomeFacilidade + ':' + linkInterno),
    });
  }
}

function dataLayerServicosFacilidades(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':facilidades',
      eventAction: 'clique:menu',
      eventLabel: 'meus-servicos:' + trataTextoDataLayer(label),
    });
  }
}

function dataLayerMapaInterno(label, piso) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':mapa-interno',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer(piso),
    });
  }
}

//Data lake acontece
function dataLayerAcontece(nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':geral',
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer('acontece:' + nomeItem),
    });
  }
}

//Data fique por dentro
function dataLayerFiquePorDentro(nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':geral',
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer('fique-por-dentro:' + nomeItem),
    });
  }
}

function dataLayerFiquePorDentroCategorias(nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fique-por-dentro',
      eventAction: 'clique:menu',
      eventLabel: trataTextoDataLayer(nomeItem),
    });
  }
}

function dataLayerFiquePorDentroArtigo(categoria, nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fique-por-dentro:artigo',
      eventAction: 'clique:artigo',
      eventLabel: trataTextoDataLayer(categoria + ':' + nomeItem),
    });
  }
}

function dataLayerFiquePorDentroFooter(categoria, nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fique-por-dentro',
      eventAction: 'clique:card',
      eventLabel: trataTextoDataLayer(categoria + ':' + nomeItem),
    });
  }
}

function dataLayerFiquePorDentroVerTodas() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fique-por-dentro',
      eventAction: 'clique:link',
      eventLabel: 'ver-todas',
    });
  }
}

function dataLayerFiquePorDentroDestaque(categoria, nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fique-por-dentro',
      eventAction: 'clique:destaque',
      eventLabel: trataTextoDataLayer(categoria + ':' + nomeItem),
    });
  }
}

function dataLayerFiquePorDentroNewsletter(nomeCampo) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fique-por-dentro:artigo',
      eventAction: 'interacao:newsletter',
      eventLabel: 'preencheu-campo:' + trataTextoDataLayer(nomeCampo),
    });
  }
}

function dataLayerFiquePorDentroNewsletterEnviar() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fique-por-dentro:artigo',
      eventAction: 'clique:newsletter',
      eventLabel: 'enviar',
    });
  }
}

function dataLayerFiquePorDentroNewsletterCallback(status) {
  if (window.dataLayer && window.dataLayer !== '') {
    const statusDecode = $('<textarea />').html(status).text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fique-por-dentro:artigo',
      eventAction: 'callback:newsletter',
      eventLabel: trataTextoDataLayer(statusDecode),
    });
  }
}

//Data lake login
function dataLayerLogin(element, isBotao) {
  if (window.dataLayer && window.dataLayer !== '') {
    let tipoClick = '';
    const label = $(element).text();
    if (isBotao) {
      tipoClick = 'botao';
    } else {
      tipoClick = 'link';
    }
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':bem-vindo',
      eventAction: 'clique:' + tipoClick,
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

//Data lake fila de espera
function dataLayerFilaDeEsperaEntrarNaFila() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:minhas-filas',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer('entrar-em-uma-fila'),
    });
  }
}
function dataLayerCliqueBtnEntrarFilaDeEspera(nomeLoja) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:fila-espera',
      eventAction: 'clique:botao:fila-espera',
      eventLabel: trataTextoDataLayer('entrar-na-fila:' + nomeLoja),
    });
  }
}
function dataLayerCliqueBtnAtualizarFilaDeEspera(nomeLoja) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:fila-espera',
      eventAction: 'clique:botao:fila-espera',
      eventLabel: trataTextoDataLayer('atualizar:' + nomeLoja),
    });
  }
}
function dataLayerCliqueWppFilaDeEspera(nomeLoja) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:fila-espera',
      eventAction: 'clique:botao:fila-espera',
      eventLabel: trataTextoDataLayer('receber-aviso-pelo-whatsapp:' + nomeLoja),
    });
  }
}
function dataLakeCliqueBotaoFilaDeEspera(label, tipo) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':fila-espera',
      eventAction: 'clique:' + tipo,
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLayerInfoUsuario(infoUsuario) {
  if (window.dataLayer && window.dataLayer !== '' && infoUsuario && infoUsuario !== '') {
    window.dataLayer.push({
      event: 'dados_usuario',
      user_id: infoUsuario.id,
      cpf_hasheado: infoUsuario.cpf,
      email_hasheado: infoUsuario.email,
    });
  }
}

function trataNomeLoja(url) {
  if (!url.includes('?')) {
    if (url.includes('lojas')) {
      return url.split('lojas/').pop().replace('.htm', '');
    } else if (url.includes('alimentacao')) {
      return url.split('alimentacao/').pop().replace('.htm', '');
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

//Data lake b2c

function dataLayerB2c() {
  if (window.dataLayer && window.dataLayer !== '') {
    const tipo = LumisPortal.getCookie('tipo');
    const cpf = LumisPortal.getCookie('cpf');
    const email = LumisPortal.getCookie('email');
    const id = LumisPortal.getCookie('id');

    if (tipo && tipo != null && tipo != '') {
      document.cookie = 'tipo=;max-age=0;path=/';
      document.cookie = 'cpf=;max-age=0;path=/';
      document.cookie = 'email=;max-age=0;path=/';
      document.cookie = 'id=;max-age=0;path=/';

      window.dataLayer.push({
        event: 'genericEvent',
        eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada',
        eventAction: tipo,
        dimension1: id,
      });
    }
  }
}

function dataLayerGetClient() {
  window.dataLayer = window.dataLayer || [];

  if (window.dataLayer && window.dataLayer !== '') {
    const logged = LumisPortal.getCookie('lumIsLoggedUser');

    if (logged == 'true') {
      $.ajax({
        url: g_LumisRoot_href + 'aliansce/shopping/rest/aliansce/datalayer/getAuth',
        type: 'GET',
        success: function (data) {
          if (data.auth == 'true') {
            dataLayerInfoUsuario(data);
          }
        },
      });
    }
  }
}

function dataLakeCliquePromocao(label, tipo, nomePromocao) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory:
        trataEmpreendimentoIdDataLayer() + ':promocao:' + trataTextoDataLayer(nomePromocao),
      eventAction: 'clique:' + tipo,
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLakeCliquePromocaoNotas(label, tipo, nomePromocao) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory:
        trataEmpreendimentoIdDataLayer() + ':promocao:' + trataTextoDataLayer(nomePromocao),
      eventAction: 'clique:' + tipo + ':notas',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLakeCliqueAgenda(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':agenda-eventos',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLakeAgendaCliqueCheckBox(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    let checkCampo = '';
    const isChecked = $(element).prop('checked');
    const nomeCheck = $(element).next().text();

    if (isChecked) {
      checkCampo = 'marcou';
    } else {
      checkCampo = 'desmarcou';
    }

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':agenda-eventos',
      eventAction: 'interacao:checkbox:filtro',
      eventLabel: trataTextoDataLayer(checkCampo + ':' + nomeCheck),
    });
  }
}

function dataLakeTrabalheConosco(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const label = $(element).text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':trabalhe-conosco',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLakeQuestionario() {
  if (window.dataLayer && window.dataLayer !== '') {
    const nomeEvento = $('.promo-list__title').text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada-inscricao-eventos',
      eventAction: 'clique:botao:evento',
      eventLabel: trataTextoDataLayer('enviar:' + nomeEvento),
    });
  }
}

function dataLakeCliqueAvisoAoLojistaLocker() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:locker-drive',
      eventAction: 'clique:botao:locker/drive',
      eventLabel: trataTextoDataLayer('aviso-ao-lojista'),
    });
  }
}
function dataLakeVerInstrucoes() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:locker-drive',
      eventAction: 'clique:botao:locker/drive',
      eventLabel: trataTextoDataLayer('ver-instrucoes'),
    });
  }
}

function dataLakeCliqueBotaoPeg(label, tipo) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:peg',
      eventAction: 'clique:' + tipo,
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLakeMenuAtalho(nomeBtn) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':home',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer('menu-atalho:' + nomeBtn),
    });
  }
}

function dataLayerCliqueCadastroVantagens() {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':vantagens',
      eventAction: 'clique:botao',
      eventLabel: trataTextoDataLayer('cadastro'),
    });
  }
}

function dataLayerVitrineFiltro(filtroNome, filtroValor) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':vitrine-produtos',
      eventAction: 'interacao:filtro:' + filtroNome,
      eventLabel: trataTextoDataLayer(filtroValor),
    });
  }
}

function dataLayerVitrineBotao(nomeBotao, nomeLoja, produto) {
  if (window.dataLayer && window.dataLayer !== '') {
    let nomePagina = '';
    let eventLabel = '';

    if (typeof isHomeAliansce !== 'undefined') {
      nomePagina = ':home';
      eventLabel = 'vitrine-produtos:';
    } else nomePagina = ':vitrine-produtos';

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + nomePagina,
      eventAction: 'clique:botao:produto',
      eventLabel: trataTextoDataLayer(eventLabel + produto + ':' + nomeBotao),
      dimension5: trataTextoDataLayer(nomeLoja),
    });
  }
}

function dataLayerVitrineProduto(nomeLoja, produto) {
  if (window.dataLayer && window.dataLayer !== '') {
    let nomePagina = '';
    let eventLabel = '';

    if (typeof isHomeAliansce !== 'undefined') {
      nomePagina = ':home';
      eventLabel = 'vitrine-produtos:';
    } else nomePagina = ':vitrine-produtos';

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + nomePagina,
      eventAction: 'clique:produto',
      eventLabel: trataTextoDataLayer(eventLabel + produto),
      dimension5: trataTextoDataLayer(nomeLoja),
    });
  }
}

function dataLayerVitrineVerMais() {
  if (window.dataLayer && window.dataLayer !== '') {
    let nomePagina = '';
    let eventLabel = '';

    if (typeof isHomeAliansce !== 'undefined') {
      nomePagina = ':home';
      eventLabel = 'vitrine-produtos:';
    } else nomePagina = ':vitrine-produtos';

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + nomePagina,
      eventAction: 'clique:link',
      eventLabel: eventLabel + 'ver-mais-produtos',
    });
  }
}

function dataLayerConfirmaReserva(nomeLoja, nomeElemento) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'reservar_restaurante',
      nome_elemento: nomeElemento,
      nome_loja: trataTextoDataLayer(nomeLoja),
    });
  }
}

function dataLayerCancelaReserva(nomeLoja) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:reservas',
      eventAction: 'clique:botao:reserva',
      eventLabel: 'cancelar-reserva',
      dimension5: trataTextoDataLayer(nomeLoja),
    });
  }
}

function dataLayerPoliticas(elem) {
  if (window.dataLayer && window.dataLayer !== '') {
    const label = $(elem).text();

    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':politicas',
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLayerTeatro(nomePeca) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':teatro',
      eventAction: 'clique:link',
      eventLabel: trataTextoDataLayer(nomePeca),
    });
  }
}

function dataLayerTeatroMaisDetalhes(nomePeca) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':teatro',
      eventAction: 'clique:link',
      eventLabel: 'mais-detalhes:' + trataTextoDataLayer(nomePeca),
    });
  }
}

function dataLayerInscricaoEventoCheckBox(element, nomeEvento) {
  let acao = $(element).is(':checked');
  const nomeItem = $(element).next().text();
  if (acao) {
    acao = 'marcado';
  } else {
    acao = 'desmarcado';
  }
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory:
        trataEmpreendimentoIdDataLayer() + ':area-logada:' + trataTextoDataLayer(nomeEvento),
      eventAction: 'interacao:checkbox',
      eventLabel: acao + ':' + trataTextoDataLayer(nomeItem),
    });
  }
}

function dataLayerInscricaoEventoComboBox(element, nomeEvento) {
  const nomeItem = $(element).find(':selected').text();
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory:
        trataEmpreendimentoIdDataLayer() + ':area-logada:' + trataTextoDataLayer(nomeEvento),
      eventAction: 'interacao:combobox',
      eventLabel: 'selecionou' + ':' + trataTextoDataLayer(nomeItem),
    });
  }
}
function dataLayerFiltroLojas(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const optionFiltro = $(element).find('option:selected').text();
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'interacao:filtro',
      eventLabel: trataTextoDataLayer(optionFiltro),
    });
  }
}
function dataLayerBusca(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const ordenacao = $(element).find('option:selected').text();
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':busca',
      eventAction: 'interacao:ordenacao',
      eventLabel: trataTextoDataLayer(ordenacao),
    });
  }
}
function dataLayerComoChegar(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const nomeItem = $(element).find('.inner-page__wrapper').children('h3').text();
    const check = $(element).prev().is(':checked');
    let status = '';
    if (!check) {
      status = 'abriu';
    } else {
      status = 'fechou';
    }
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':como-chegar',
      eventAction: 'clique:item',
      eventLabel: status + ':' + trataTextoDataLayer(nomeItem),
    });
  }
}
function dataLayerModal(item) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':geral',
      eventAction: 'clique:modal',
      eventLabel: item,
    });
  }
}
function dataLayerNotificacaoCheckBox(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const check = $(element).is(':checked');
    let status = '';
    if (check) {
      status = 'check';
    } else {
      status = 'uncheck';
    }
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':home',
      eventAction: 'interacao:checkbox',
      eventLabel: 'autorizacao-de-notificacao:' + status,
    });
  }
}
function dataLayerPromocaoFormulario(nomeCampo, nomePromocao) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory:
        trataEmpreendimentoIdDataLayer() + ':area-logada:' + trataTextoDataLayer(nomePromocao),
      eventAction: 'preencheu-campo:form-promocoes',
      eventLabel: trataTextoDataLayer(nomeCampo),
    });
  }
}
function dataLayerPromocaoFormularioCheckBox(element, nomePromocao) {
  if (window.dataLayer && window.dataLayer !== '') {
    let status = '';
    const nomeItem = $(element).next().text();
    if ($(element).is(':checked')) {
      status = 'marcou';
    } else {
      status = 'desmarcou';
    }
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory:
        trataEmpreendimentoIdDataLayer() + ':area-logada:' + trataTextoDataLayer(nomePromocao),
      eventAction: 'interacao:checkbox',
      eventLabel: status + ':' + trataTextoDataLayer(nomeItem),
    });
  }
}
function dataLayerPromocoesBotao(label) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':area-logada:promocoes',
      eventAction: 'clique:botao:promocao',
      eventLabel: trataTextoDataLayer(label),
    });
  }
}

function dataLayerCallbackFormularioPromocao(codigoPromocao, nomePromocao) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'cadastro_promocao',
      titulo: trataTextoDataLayer(nomePromocao),
      id_promocao: codigoPromocao,
    });
  }
}

function dataLayerCliqueFormaChegar(tipoFormaChegar) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':como-chegar',
      eventAction: 'clique:forma-chegar',
      eventLabel: trataTextoDataLayer(tipoFormaChegar),
    });
  }
}

function dataLayerCanalVenda(element) {
  if (window.dataLayer && window.dataLayer !== '') {
    const canalVenda = $(element).val();
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':' + dataLayerTipo(),
      eventAction: 'interacao:filtro',
      eventLabel: trataTextoDataLayer(canalVenda),
    });
  }
}

function dataLayerCliqueHotsite(nomeCadastro, position, actionHotsite) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':hotsite',
      eventAction: 'clique:' + actionHotsite,
      eventLabel: trataTextoDataLayer(nomeCadastro),
      dimension10: position,
    });
  }
}

function dataLayerCliqueEnquete(nomeItem, actionEnquete) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'genericEvent',
      eventCategory: trataEmpreendimentoIdDataLayer() + ':enquete',
      eventAction: 'clique:' + actionEnquete,
      eventLabel: trataTextoDataLayer(nomeItem),
    });
  }
}

function dataLayerVisualizacaoPagina(pagina) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'screen_view',
      screen_name: pagina.pathname,
    });
  }
}

function dataLayerClique(nomeEvento, nomeItem) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: nomeEvento,
      titulo: trataTextoDataLayer(nomeItem),
    });
  }
}

function dataLayerAtendimentoWhatsapp(nomeElemento, nomeLoja) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'atendimento_whatsapp',
      nome_elemento: trataTextoDataLayer(nomeElemento),
      nome_loja: trataTextoDataLayer(nomeLoja),
    });
  }
}

function dataLayerCallbackEnvioNotaPromocao(categoriaEnvio) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'envio_nota_fiscal',
      categoria: trataTextoDataLayer(categoriaEnvio),
      nome_funil: 'nota-fiscal-promocao',
    });
  }
}

function dataLayerCadastroNewsletter(status) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'cadastro_newsletter',
      nome_elemento: status,
    });
  }
}

function dataLayerFormErro(codigoErro, tipoErro, etapaIndex, nomeEtapa, nomeFunil) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'visualizacao_erro',
      codigo_erro: codigoErro,
      tipo_erro: tipoErro,
      etapa_index: etapaIndex,
      nome_etapa: nomeEtapa,
      nome_funil: nomeFunil,
    });
  }
}

function dataLayerPagamentoEstacionamento(valorPago) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'pagamento_estacionamento',
      valor_pago: valorPago,
    });
  }
}

function dataLayerCanalVendaLoja(nomeLoja, element) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: 'link_externo',
      nome_loja: trataTextoDataLayer(nomeLoja),
      url_destino: $(element).attr('href'),
      nome_elemento: trataTextoDataLayer(element.innerText),
    });
  }
}

function dataLayerGenerico(event, element) {
  if (window.dataLayer && window.dataLayer !== '') {
    window.dataLayer.push({
      event: event,
      nome_elemento: trataTextoDataLayer(element),
    });
  }
}
